import packageJson from '../../package.json';
export const environment = {
	version: packageJson.version,
	production: false,
	demo:true,
	test: false,
	develop: false,
	environment: 'demo',
	service: 'demo',
	wsUrl: 'wss://apimgmt.synapsia.ai/maestroWebdemo ',
	host: 'demo.mymaia.ai',
	apiHost: 'https://apimgmt.synapsia.ai/',
	apiHostPublic: 'https://apimgmt.synapsia.ai/public/',
	bugFenderKey: ''
};